import * as React from 'react';
import { Helmet } from 'react-helmet';
import API from '../API';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function LogoutPage(props)
{
	const navigate = useNavigate();

	useEffect(() =>
	{
		window.localStorage.clear();
		navigate("/", { replace: true });
	});

    return (
        <></>
    );
}

export default LogoutPage;