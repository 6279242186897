import logo from './logo.svg';
import './App.css';
import { IndexPage, LoginPage, LogoutPage, ChatPage } from './pages';
import
{
    Route,
    Routes,
} from "react-router-dom";
import { Helmet } from "react-helmet";

function App()
{
    return (
        <>
            <div className="App">
                <Routes>
                    <Route path="/" element={<IndexPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/logout" element={<LogoutPage />} />
                    <Route path="/chat" element={<ChatPage />} />
                </Routes>
            </div>
        </>
    )
}

export default App;
