import * as React from 'react';
import { Helmet } from 'react-helmet';
import API from '../API';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function IndexPage(props)
{
	const navigate = useNavigate();

	useEffect(() =>
	{
		if (window.localStorage.getItem("token"))
		{
			API.defaults.headers.common['Authorization'] = `Bearer ${window.localStorage.getItem("token")}`;
			navigate("/chat", { replace: true });
		}
		else
		{
			navigate("/login", { replace: true });
		}
	});

    return (
        <></>
    );
}

export default IndexPage;