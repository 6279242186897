import { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Linkify from 'linkify-react';
import "./ChatMessage.css";

const myUsernames = [
	"mal1t1a",
	"mal1t1a1",
	"mal1t14",
	"mal1t141",
];

function ChatMessage(props)
{
	const { channel, from, message, userMode, dateAdded } = props;
	const isPM = channel.indexOf("#") === -1;
	const isSelf = myUsernames.includes(from.toLowerCase());
	let displayTime = new Date(dateAdded).toLocaleTimeString();
	let isSystem = from.toLowerCase() === "";

	let bgStyle = {
		background: isSelf ? "linear-gradient(180deg, rgba(53,152,255,1) 0%, rgba(9,130,255,1) 35%, rgba(9,130,255,1) 100%)" : "#e8e8eb",
		alignSelf: isSelf ? "flex-end" : "flex-start",
		color: isSelf ? "#fff" : "#000",
		"&": {
			":before": {
				background: isSelf ? "rgba(9,130,255,1)" : "#e8e8eb",
			},
		}
	};

	if (isSystem)
	{
		return (
			<>
				<Box textAlign={"center"} sx={{ p: 2, borderRadius: 4, maxWidth: "100%", overflowX: "hidden", display: "flex", flexDirection: "row", alignItems: "center", boxSizing: "border-box" }}>
					<Stack>
						<Stack flexDirection={"row"} sx={{ alignItems: "center", maxWidth: "100%" }}>
							<Divider sx={{ width: "50px" }} />
							<Stack sx={{ ml: 2, mr: 2, flexGrow: 0, flexShrink: 1, overflowX: "hidden", overflowY: "auto" }}>
								<Box>
									<Typography variant="subtitle" gutterBottom sx={{ color: "#777", whiteSpace: "break-spaces", wordWrap: "break-word" }}>
										{message}
									</Typography>
								</Box>
							</Stack>
							<Divider sx={{ width: "50px" }} />
						</Stack>
						<Typography variant="caption" sx={{ pb: 0, color: "#777", whiteSpace: "break-spaces", wordWrap: "break-word" }}>
							{displayTime}
						</Typography>
					</Stack>
				</Box>
			</>
		);
	}

	//check if the messages contains any of myUsernames

	const containsUsername = myUsernames.some((username) =>
	{
		return message.toLowerCase().indexOf(username) !== -1;
	});

	if (containsUsername)
	{
		bgStyle.background = "#ceffd4";
		bgStyle["&"][":before"].background = "#ceffd4";
	}

	return (
		<>
			<Box className={"chatMsg" + (isSelf ? "" : " other")} textAlign={"left"} sx={{ ...bgStyle, p: 2, borderRadius: 4, minWidth: "150px", maxWidth: "75dvw", boxSizing: "border-box" }}>
				<Typography variant="subtitle" gutterBottom sx={{}}>
					{userMode}{from}
				</Typography>
				<Typography variant="body1" sx={{ whiteSpace: "break-spaces", wordWrap: "break-word" }}>
					<Linkify options={{ target: "_blank" }}>
						{message}
					</Linkify>
				</Typography>
				<Typography variant="caption" sx={{ pb: 0 }}>
					{displayTime}
				</Typography>
			</Box>
		</>
	);
}

export default ChatMessage;
