import { useEffect, useState, useRef } from 'react';
import API from '../API';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import IOSocket from "../IOSocket";
import { ChatMessage, ChatInput } from '../components';

function ChatPage(props)
{
	const [chatMessages, setChatMessages] = useState([]);
	const [channels, setChannels] = useState([]);
	const [selectedChannel, setSelectedChannel] = useState(null);
	const [isStickToBottom, setIsStickToBottom] = useState(true);
	const [lastMessageDate, setLastMessageDate] = useState(0);
	const [loadingInitialMessages, setLoadingInitialMessages] = useState(true);
	const [lastMessageID, setLastMessageID] = useState(0);
	const chatBoxRef = useRef(null);
	const startChatRef = useRef(null);
	const endChatRef = useRef(null);

	console.log("refresh");

	const loadPreviewChats = async () =>
	{
		var hasError = false;
		const response = await API.get(`/history?limit=500&lastID=${lastMessageID}`).catch((err) =>
		{
			hasError = true;

			setChatMessages((prevMessages) =>
			{
				//return [...prevMessages, { channel: "", from: "", message: "Mal1t1a [~mal1t1a@2600:3c04::f03c:93ff:fedd:8942] has joined #linode", userMode: null, dateAdded: Date.now() }];
				return [...prevMessages, { channel: "", from: "", message: err.toString(), userMode: null, dateAdded: Date.now() }];
			});
		});

		if (response && response.status === 200 && response.data)
		{
			var history = response.data;
			var channelMessages = history?.channelMessages || [];
			var pms = history?.pms || [];

			var existingMessages = [];
			
			// channelMessages.map((channelMessage) =>
			// {
			// 	return { channel: channelMessage.channel, from: channelMessage.username, message: channelMessage.message, userMode: channelMessage.userMode, dateAdded: channelMessage.dateAdded };
			// });

			for (var i = 0; i < channelMessages.length; i++)
			{
				var channelMessage = channelMessages[i];
				var message = { channel: channelMessage.channel, from: channelMessage.username, message: channelMessage.message, userMode: channelMessage.userMode, dateAdded: channelMessage.dateAdded };
				existingMessages.push(message);

				if (channelMessage.id > lastMessageID)
				{
					setLastMessageID(channelMessage.id);
				}
			}

			setChatMessages(existingMessages);
		}
		else
		{
			setLoadingInitialMessages(false);
		}

		return !hasError;
	};

	const onConnect = () =>
	{
		console.log("[onConnect]");
	};

	const onDisconnect = () =>
	{
		console.log("[onDisconnect]");
	};

	const onIrcMessage = (message) =>
	{
		console.log("[onIrcMessage]", message);
	};

	const onSelectedChannel = (channel) =>
	{
		console.log("[onSelectedChannel]", channel);
		setSelectedChannel(channel);
	};

	const onChannelMessage = ({ channel, from, message, userMode }) =>
	{
		console.log("[onChannelMessage]", channel, from, message, userMode);

		setChatMessages((prevMessages) =>
		{
			return [...prevMessages, { channel, from, message, userMode, dateAdded: Date.now() }];
		});
	};

	const onUserJoin = ({ channel, nick, message }) =>
	{
		console.log("[onUserJoin]", channel, nick, message);
		//logIRCMessage(`${redEquals} ${nick} [${message.user}@${message.host}] has joined ${channel}`);

		setChatMessages((prevMessages) =>
		{
			return [...prevMessages, { channel, from: "", message: `${nick} [${message.user}@${message.host}] has joined ${channel}`, userMode: null, dateAdded: Date.now() }];
		});
	};

	const onUserPart = ({ channel, nick, reason, message }) =>
	{
		console.log("[onUserPart]", channel, nick, reason, message);
		//logIRCMessage(`${redEquals} ${nick} [${message.user}@${message.host}] has quit [${typeof reason !== "undefined" ? reason : ""}]`);

		setChatMessages((prevMessages) =>
		{
			return [...prevMessages, { channel, from: "", message: `${nick} [${message.user}@${message.host}] has quit [${typeof reason !== "undefined" ? reason : ""}]`, userMode: null, dateAdded: Date.now() }];
		});
	};

	useEffect(() =>
	{
		const setupSocketIO = async () =>
		{
			IOSocket.on("connect", onConnect);
			IOSocket.on("disconnect", onDisconnect);
			IOSocket.on("ircMessage", onIrcMessage);
			IOSocket.on("channelMessage", onChannelMessage);
			IOSocket.on("selectedChannel", onSelectedChannel);
			IOSocket.on("userJoin", onUserJoin);
			IOSocket.on("userPart", onUserPart);
	
			if (await loadPreviewChats())
			{
				console.log("Connecting to socket.io");
				IOSocket.connect();
			}
		};

		const destroySocketIO = () =>
		{
			IOSocket.off("connect", onConnect);
			IOSocket.off("disconnect", onDisconnect);
			IOSocket.off("ircMessage", onIrcMessage);
			IOSocket.off("channelMessage", onChannelMessage);
			IOSocket.off("selectedChannel", onSelectedChannel);
			IOSocket.off("userJoin", onUserJoin);
			IOSocket.off("userPart", onUserPart);

			IOSocket.disconnect();
		};

		setupSocketIO();
		return destroySocketIO;
	}, []);

	const sendMessage = async (message) =>
	{
		IOSocket.emit("input", message);
	};

	const scrollToTop = () =>
	{
		startChatRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const scrollToBottom = () =>
	{
		endChatRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const onScroll = (event) =>
	{
		if (event.target.scrollTop === 0)
		{
			setIsStickToBottom(false);
		}
		else
		{
			setIsStickToBottom(true);
		}
	};

	useEffect(() =>
	{
		if (chatBoxRef.current)
		{
			chatBoxRef.current.addEventListener("scroll", onScroll);
		}

		return () =>
		{
			if (chatBoxRef.current)
			{
				chatBoxRef.current.removeEventListener("scroll", onScroll);
			}
		};
	}, [chatBoxRef]);

	useEffect(() =>
	{
		if (isStickToBottom)
		{
			scrollToBottom();
		}
		if (!loadingInitialMessages)
		{
			let now = Date.now();
			let lastMsgDiff = now - lastMessageDate;
			if (lastMsgDiff > (1000 * 60 * 60) && selectedChannel !== null && chatMessages.length > 1)
			{
				setChatMessages((prevMessages) =>
				{
					let newMessages = [...prevMessages];
					newMessages.splice(newMessages.length - 1, 0, { channel: selectedChannel, from: "", message: "Earlier", userMode: null, dateAdded: now });
					return newMessages;
				});
			}
		}
		else
		{
			setLoadingInitialMessages(false);
		}
		setLastMessageDate(Date.now());
	}, [chatMessages]);

	const displayMessages = chatMessages.map((chatMessage, index) =>
	{
		return (
			<ChatMessage key={index} channel={chatMessage.channel} from={chatMessage.from} message={chatMessage.message} userMode={chatMessage.userMode} dateAdded={chatMessage.dateAdded} />
		);
	});

	return (
		<>
			<Helmet>
				<title>Chat</title>
			</Helmet>

			{/* Chat history */}
			<Box sx={{ flexGrow: 1, height: "calc(100dvh - 107px)", overflow: "auto" }}>
				<Stack spacing={1} ref={chatBoxRef} sx={{ p: 2, alignItems: "flex-start", overflow: "hidden" }}>
					<div ref={startChatRef}></div>
					{displayMessages}
					<div ref={endChatRef}></div>
				</Stack>
			</Box>

			<Divider />

			<ChatInput selectedChannel={selectedChannel} sendMessage={sendMessage} />
		</>
	);
}

export default ChatPage;