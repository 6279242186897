import Axios from 'axios';

const API = Axios.create({
	baseURL: 'https://mircbot.mal1t1a.ca/api',
});

//check localStorage for token
if (window.localStorage.getItem("token"))
{
	API.defaults.headers.common['Authorization'] = `Bearer ${window.localStorage.getItem("token")}`;
}

export default API;