import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { CircularProgress } from '@mui/material';
import API from '../API';
import { useNavigate } from 'react-router-dom';

function Copyright(props)
{
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mylesvanevery.ca">
                Myles Vanevery
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

function LoginPage(props)
{
    const [submitting, setSubmitting] = React.useState(false);
	const [error, setError] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState("");
	const navigate = useNavigate();

    const handleSubmit = async (event) =>
    {
        event.preventDefault();

        setSubmitting(true);
		setError(false);
		setErrorMessage("");

		const response = await API.post("/login", {
			username: event.target.username.value,
			password: event.target.password.value,
		}).catch((err) =>
		{
			console.error(err);
			setError(true);
			setErrorMessage("Invalid username or password.");
		});

		setSubmitting(false);

		if (response && response.status === 200)
		{
			console.log("Response data:", response.data);
			window.localStorage.setItem("token", response.data.token);
			API.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
			//redirect to: response.data.redirect
			navigate(response.data.redirect);
		}
		else if (response && response.status === 401 && response.data)
		{
			setErrorMessage(response.data.message);
		}
    };

	const handleUsernameKeyDown = (event) =>
	{
		if (event.key === "Enter")
		{
			event.preventDefault();
			event.target.form.password.focus();
		}
	};

    return (
        <>
            <Helmet>
                <title>Login Required</title>
            </Helmet>
            <ThemeProvider theme={theme}>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={7}
                        sx={{
                            backgroundImage: 'url(https://source.unsplash.com/random/?tech,computers,websites)',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign in
                            </Typography>
							{error && <Typography component="h3" variant="h6" sx={{ color: "red" }}>
								{errorMessage}
							</Typography>}
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
									onKeyDown={handleUsernameKeyDown}
									inputProps={{ enterkeyhint: "next" }}

                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
									inputProps={{ enterkeyhint: "done" }}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={submitting}
                                >
                                    Sign In
                                    {submitting && <CircularProgress size={24} sx={{ ml: 2 }} />}
                                </Button>
                                {/* <Grid container>
                                    <Grid item xs>
                                        <Link href="#" variant="body2">
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="#" variant="body2">
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid> */}
                                <Copyright sx={{ mt: 5 }} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    );
}

export default LoginPage;