import React, { useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';


function ChatInput(props)
{
	const { selectedChannel, sendMessage } = props;
	const [message, setMessage] = useState("");

	const onMessageChange = (event) =>
	{
		setMessage(event.target.value);
	};

	const onKeyDown = (event) =>
	{
		switch (event.keyCode)
		{
			case 13:
				event.preventDefault();
				sendMessage(message);
				setMessage("");
				break;
			case 27:
				event.preventDefault();
				setMessage("");
				break;
			default:
				//console.log(event.keyCode);
				break;
		}
	}

	return (
		<Box sx={{ p: 2, height: "106px", boxSizing: "border-box" }}>
			{selectedChannel === null && <>
				<CircularProgress />
			</>}
			{selectedChannel !== null && <>
				<TextField
					fullWidth
					label={"Message to " + selectedChannel}
					placeholder="Your message here"
					variant="standard"
					value={message}
					onChange={onMessageChange}
					onKeyDown={onKeyDown}
					inputProps={{ enterKeyHint: "send" }}
				/>
			</>}
		</Box>
	);
}

export default ChatInput;
